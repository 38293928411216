<template>
  <div class="tasks-by-status-container">
    <div class="grey--text py-2 text-center body-2" v-show="showNoListSelected">
      Please select a list to view its tasks.
    </div>
    <div class="grey--text py-2 text-center body-2" v-show="showNoTasks">
      No Tasks Found
    </div>
    <div class="grey--text text-center body-2" v-show="showLoadingTasks">
      <v-overlay :value="isLoadingTasks" :opacity="0.02" color="#4483f7">
        <div class="pa-5 border-radius-6 custom-loader">
          <v-progress-circular
            indeterminate
            size="40"
            color="primary"
            class="mx-1"
          ></v-progress-circular>
        </div>
      </v-overlay>
    </div>
    <v-expansion-panels flat accordion multiple v-model="openedPanels">
      <v-expansion-panel
        v-for="statusData in tasksByListStatus"
        :key="`status_${statusData.id}`"
        class="status-tasks-container"
      >
        <StatusTasks
          v-bind="statusData"
          @openAddEditTaskDialog="
            (task, status_id = null) =>
              $emit('openAddEditTaskDialog', task, status_id)
          "
          @openTaskDetailsDialog="
            (task_id) => $emit('openTaskDetailsDialog', task_id, list_id)
          "
          @deleteTask="(task_id) => $emit('deleteTask', task_id)"
        />
      </v-expansion-panel>
    </v-expansion-panels>

    <v-snackbar
      v-model="alert.show"
      :timeout="3000"
      :color="alert.color"
      align-baseline="center"
    >
      {{ alert.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="alert.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay absolute :value="isErrorOccured" color="white" opacity="1">
      <div class="text-center">
        <p class="primary--text" style="font-size: 50px; margin: 0px">Oops !</p>
        <p class="grey--text subtitle-1">{{ errorMessage }}</p>
        <v-btn
          color="primary"
          class="mt-3"
          rounded
          depressed
          @click="$router.push(`/projects/`)"
          ><v-icon size="18" class="mr-3">mdi-arrow-left</v-icon>Go Back</v-btn
        >
      </div>
    </v-overlay>
  </div>
</template>

<script>
import StatusTasks from "../../common/project/StatusTasks.vue";
import { mapMutations, mapState } from "vuex";

export default {
  props: {
    list_id: Number,
    search: [String, null],
    selectedTeamMembers: {
      type: Array,
      default: () => [],
    },
    selectedPriorities: {
      type: Array,
      default: () => [],
    },
    selectedTags: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    StatusTasks,
  },
  data() {
    return {
      isLoadingTasks: false,
      openedPanels: [],
      cancelTokenSource: null,
      isErrorOccured: false,
      errorMessage: "",
      alert: {
        show: false,
        message: "",
        color: "",
      },
    };
  },
  watch: {
    // fetch the tasks for new list id
    list_id: function () {
      this.setTasksByListStatus([]);
      this.openedPanels = [];

      if (this.list_id) {
        this.getListTasksByStatus();
      }
    },
    search: function () {
      this.getListTasksByStatus();
    },
    selectedTeamMembers: {
      handler: function () {
        this.getListTasksByStatus();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("tasks", ["tasksByListStatus"]),
    showNoListSelected() {
      return !this.list_id && !this.isLoadingTasks;
    },
    showNoTasks() {
      return (
        this.list_id && !this.tasksByListStatus.length && !this.isLoadingTasks
      );
    },
    showLoadingTasks() {
      // hiding loader when searching data to avoid flicker
      return !this.search && this.isLoadingTasks;
    },
  },
  methods: {
    ...mapMutations("tasks", ["setTasksByListStatus"]),
    showAlertMessage(isSucessMsg, message) {
      this.alert = {
        message: message || "",
        color: isSucessMsg ? "green accent-4" : "red accent-2",
        show: true,
      };
    },
    getListTasksByStatus() {
      this.isLoadingTasks = true;

      let requestStartTime = new Date().getTime();

      // Cancel any previous requests
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }

      // Create a new CancelToken source
      this.cancelTokenSource = this.$axios.CancelToken.source();

      this.$axios
        .get(
          `/list/${this.list_id}/tasks/by_status?search=${
            this.search || ""
          }&selectedTeamMembers=${this.selectedTeamMembers.join(
            ","
          )}&selectedPriorities=${this.selectedPriorities.join(
            ","
          )}&selectTags=${this.selectedTags.join(",")}`,
          {
            cancelToken: this.cancelTokenSource.token,
          }
        )
        .then((response) => {
          const resData = response.data;

          if (resData.status) {
            // only displaying status which have one or more tasks when search term is present
            if (this.search || this.selectedTeamMembers?.length) {
              resData.data.tasksByListStatus =
                resData.data.tasksByListStatus.filter((status) => {
                  return status.tasks && status.tasks.length;
                });
            }

            this.setTasksByListStatus(resData.data.tasksByListStatus);

            this.openedPanels = Array.from(
              Array(resData.data.tasksByListStatus.length + 1).keys()
            );
          } else {
            this.isErrorOccured = true;
            this.errorMessage = resData.message;
            // this.showAlertMessage(
            //   false,
            //   resData.message || "Something went wrong"
            // );
          }
        })
        .catch((error) => {
          if (this.$axios.isCancel(error)) {
            // Request canceled
            console.log("Request canceled:");
          } else {
            this.showAlertMessage(false, "Something went wrong");
          }
        })
        .finally(() => {
          let requestEndTime = new Date().getTime();
          let totalTimeElapsed = requestEndTime - requestStartTime;

          if (totalTimeElapsed > 200) {
            this.isLoadingTasks = false;
          } else {
            setTimeout(() => {
              this.isLoadingTasks = false;
            }, 200);
          }
        });
    },
  },
  mounted() {
    if (this.list_id) {
      this.getListTasksByStatus();
    }
  },
  beforeDestroy() {
    this.setTasksByListStatus([]);
  },
};
</script>

<style scoped>
.tasks-by-status-container {
  background: var(--background-color);
  padding: 10px 0px;
}

.status-tasks-container {
  background: var(--background-color) !important;
}
</style>
